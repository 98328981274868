import {
  faFacebook,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"
import React, { ReactElement } from "react"

const currentYear = new Date().getFullYear()

const Footer = (): ReactElement => (
  <footer className="bg-ul-midnight-blue">
    <div className="max-w-5xl mx-auto px-6 py-10">
      <div className="flex flex-col md:flex-row mb-8">
        <div className="grid gap-4 grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 grow text-white font-light md:basis-1/2 mb-6 sm:mb-0">
          <div>
            <div className="font-semibold text-lg">UL Enterprise</div>
            <a className="my-3 block" href="https://ul.org/">
              UL.org
            </a>
            <a className="my-3 block" href="https://ulse.org/#">
              ULSE.org
            </a>
            <a className="my-3 block" href="https://ul.com/">
              UL.com
            </a>
          </div>

          <div>
            <div className="font-semibold text-lg">About</div>
            <Link className="my-3 block" to="/blog">
              Blog
            </Link>
            <Link className="my-3 block" to="/careers">
              Careers
            </Link>
            <Link className="my-3 block" to="/outcomes">
              Outcomes
            </Link>
            <Link className="my-3 block" to="/safety-alerts">
              Safety Alerts
            </Link>
          </div>

          <div>
            <div className="font-semibold text-lg">Legal</div>
            <a
              className="my-3 block"
              href="https://www.ul.com/customer-resources/online-policies/privacy-notice"
            >
              Privacy Policy
            </a>
            <a
              className="my-3 block"
              href="https://www.ul.com/customer-resources/online-policies/terms-of-use"
            >
              Terms of Use
            </a>
          </div>
        </div>
        <div className="text-white md:basis-1/2">
          <div className="font-light mt-4 block">
            <svg
              className="max-h-20 xs:h-20 mx-auto"
              data-name="Layer 2"
              viewBox="0 0 504.58 144"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g fill="#ffffff" data-name="Layer 2">
                <line
                  stroke="#fff"
                  strokeMiterlimit="10"
                  strokeWidth="4px"
                  x1="363.29"
                  x2="363.29"
                  y1="36.7"
                  y2="106.67"
                />
                <path d="m396.14 38.11h7.27c8.34 0 12.81 5.25 12.81 15.05s-4.47 15.05-12.81 15.05h-7.27v-30.1zm7.44 25.16c4.69 0 6.79-3.44 6.79-10.11s-2.11-10.1-6.79-10.1h-1.72v20.21h1.72z" />
                <path d="m423.44 37.12c1.85 0 3.4 1.5 3.4 3.4s-1.55 3.35-3.4 3.35-3.4-1.51-3.4-3.35 1.59-3.4 3.4-3.4zm-2.75 9.46h5.46v21.63h-5.46v-21.63z" />
                <path d="m429.76,71.05l3.57-3.91c-1.81-.9-3.01-2.49-3.01-4.43s1.25-3.53,2.88-4.47c-1.42-1.25-2.28-3.05-2.28-5.12,0-4.13,3.35-7.09,7.91-7.09,1.2,0,2.28.21,3.27.56l2.71-4.34,3.91,3.18-3.48,3.31c.99,1.2,1.55,2.71,1.55,4.39,0,4.13-3.35,7.09-7.95,7.09-.82,0-1.59-.09-2.37-.3-.77.39-1.2.95-1.2,1.72,0,1.03.73,1.68,2.24,1.68h3.27c4.21,0,7.31,2.84,7.31,6.79s-3.74,7.22-8.69,7.22c-5.33,0-8.73-2.92-9.63-6.28Zm9.59,1.85c2.24,0,3.4-1.12,3.4-2.62,0-1.29-.95-2.41-2.92-2.41h-2.15l-2.88,3.05c.95,1.12,2.45,1.98,4.56,1.98Zm-.52-16.86c1.59,0,2.67-1.12,2.67-2.92s-1.08-2.92-2.67-2.92-2.62,1.12-2.62,2.92,1.08,2.92,2.62,2.92Z" />
                <path d="m453.8 37.12c1.85 0 3.4 1.5 3.4 3.4s-1.55 3.35-3.4 3.35-3.4-1.51-3.4-3.35 1.59-3.4 3.4-3.4zm-2.75 9.46h5.46v21.63h-5.46v-21.63z" />
                <path d="m463.56,62.02v-10.92h-3.83v-4.51h.6c2.58,0,3.83-1.55,3.96-4.13l.09-2.02h4.69v6.15h4.56v4.51h-4.6v10.36c0,1.5,1.12,2.54,2.71,2.54.56,0,1.16-.13,1.55-.26v4.56c-.82.26-1.76.43-2.71.43-4.26,0-7.01-2.92-7.01-6.71Z" />
                <path d="m475.86,62.62c0-4.21,3.1-6.79,8.47-7.52l2.37-.34v-1.38c0-1.72-1.03-2.67-2.41-2.67-1.46,0-2.54.99-2.67,2.84h-5.46c.3-4.56,3.57-7.53,8.17-7.53s7.74,2.97,7.74,7.74v9.29c0,.82.52,1.38,1.38,1.38.13,0,.3,0,.52-.04v3.96c-.6.17-1.29.26-1.98.26-2.06,0-3.61-.95-4.08-3.1h-.26c-.86,1.63-2.71,3.23-5.72,3.23-3.61,0-6.06-2.41-6.06-6.11Zm7.48,1.46c1.89,0,3.35-1.93,3.35-4.56v-1.08l-1.25.26c-2.71.47-4.08,1.63-4.08,3.4,0,1.29.99,1.98,1.98,1.98Z" />
                <path d="m496.71 37.25h5.46v30.96h-5.46v-30.96z" />
                <path d="m394.85,101.44h5.93c.04,2.97,1.38,4.21,3.57,4.21,1.81,0,3.05-1.16,3.05-3.1s-1.16-3.18-4-4.82l-2.62-1.5c-3.96-2.24-5.63-5.07-5.63-8.56,0-4.56,3.7-8.08,8.77-8.08s8.86,3.22,8.9,8.73h-5.98c-.04-2.41-1.2-3.65-3.01-3.65-1.55,0-2.84,1.16-2.84,2.71,0,1.72,1.03,3.01,4.13,4.73l2.06,1.16c4.04,2.24,6.02,5.42,6.02,9.25,0,4.82-3.57,8.21-8.94,8.21-5.72,0-9.42-3.53-9.42-9.29Z" />
                <path d="m416.3,104.62c0-4.21,3.1-6.79,8.47-7.52l2.37-.34v-1.38c0-1.72-1.03-2.67-2.41-2.67-1.46,0-2.54.99-2.67,2.84h-5.46c.3-4.56,3.57-7.53,8.17-7.53s7.74,2.97,7.74,7.74v9.29c0,.82.52,1.38,1.38,1.38.13,0,.3,0,.52-.04v3.96c-.6.17-1.29.26-1.98.26-2.06,0-3.61-.95-4.08-3.1h-.26c-.86,1.63-2.71,3.23-5.72,3.23-3.61,0-6.06-2.41-6.06-6.11Zm7.48,1.46c1.89,0,3.35-1.93,3.35-4.56v-1.08l-1.25.26c-2.71.47-4.08,1.63-4.08,3.4,0,1.29.99,1.98,1.98,1.98Z" />
                <path d="m439.14,93.1h-3.87v-4.51h3.87v-2.58c0-4.9,3.18-7.27,7.01-7.27,1.89,0,3.7.65,4.6,1.42l-1.98,3.91c-.6-.34-1.16-.56-1.85-.56-1.46,0-2.32.95-2.32,2.49v2.58h4.56v4.51h-4.56v17.11h-5.46v-17.11Z" />
                <path d="m451.18,99.37c0-6.84,3.4-11.35,8.77-11.35s8.56,4.17,8.56,11.18c0,.3,0,.77-.09,1.46h-11.78c.09,3.18,1.33,5.42,3.53,5.42,1.38,0,2.49-.82,2.71-2.58h5.46c-.47,4.43-3.7,7.22-8.17,7.22-5.72,0-8.99-4.52-8.99-11.35Zm11.78-2.45c-.17-2.62-1.29-4.3-3.01-4.3s-2.92,1.68-3.23,4.3h6.24Z" />
                <path d="m474.27,104.02v-10.92h-3.83v-4.51h.6c2.58,0,3.83-1.55,3.96-4.13l.09-2.02h4.69v6.15h4.56v4.51h-4.6v10.36c0,1.5,1.12,2.54,2.71,2.54.56,0,1.16-.13,1.55-.26v4.56c-.82.26-1.76.43-2.71.43-4.26,0-7.01-2.92-7.01-6.71Z" />
                <path d="m489.23,114.55c1.2,0,2.67-.64,3.18-2.45l.52-1.98-6.84-21.54h5.68l3.65,14.02h.26l3.22-14.02h5.68l-6.71,23.82c-1.42,4.99-4.77,6.92-7.91,6.92-1.25,0-2.06-.17-2.84-.39v-4.69c.73.21,1.46.3,2.11.3Z" />
                <path d="m72,0C32.24,0,0,32.24,0,72s32.24,72,72,72,72-32.24,72-72S111.76,0,72,0Zm0,121.92c-27.57,0-49.92-22.35-49.92-49.92s22.35-49.92,49.92-49.92,49.92,22.35,49.92,49.92-22.35,49.92-49.92,49.92Zm-31.23-84.12h11.67v37.61c0,2.39.73,4.45,4.05,4.45,3.06,0,3.99-2.66,3.99-4.78v-37.27h11.67v36.08c0,8.44-3.78,14.95-15.73,14.95-9.89,0-15.66-4.72-15.66-14.95v-36.08h0Zm35.99,67.9h25.96v-9.99h-14.29v-39.88h-11.67v49.87h0Z" />
                <path d="m166.48,37.56h9.03c1.86,0,3.5.39,4.9,1.18,1.4.79,2.49,1.87,3.27,3.25s1.16,2.97,1.16,4.77c0,1.43-.24,2.7-.71,3.78-.47,1.09-1.15,2.02-2.02,2.79-.88.77-1.9,1.33-3.07,1.68l.69-3.1,5.63,15.74h-5.98l-5.12-14.19,3.35,2.45h-5.38v11.74h-5.76v-30.1Zm10.62,12.88c.6-.36,1.08-.85,1.42-1.48s.52-1.36.52-2.19c0-1.29-.37-2.32-1.12-3.1-.75-.77-1.72-1.16-2.92-1.16h-2.75v8.47h2.8c.77,0,1.46-.18,2.06-.54Z" />
                <path d="m192.68,66.78c-1.36-.93-2.39-2.25-3.1-3.96-.7-1.71-1.05-3.69-1.05-5.96s.36-4.33,1.08-6.02c.72-1.69,1.73-3,3.05-3.93,1.32-.93,2.87-1.4,4.64-1.4s3.23.43,4.54,1.29c1.3.86,2.31,2.14,3.01,3.85.7,1.71,1.05,3.71,1.05,6,0,.2,0,.4-.02.6-.01.2-.04.44-.06.73-.03.03-.04.05-.02.06s.02.05.02.11h-13.97v-3.83h11.78l-3.31,1.93c.06-1.2-.01-2.28-.21-3.22-.2-.95-.54-1.68-1.03-2.19-.49-.52-1.08-.77-1.76-.77s-1.25.24-1.76.71c-.52.47-.9,1.14-1.16,2-.26.86-.39,1.86-.39,3.01v2.02c0,1.09.14,2.06.43,2.92.29.86.68,1.54,1.18,2.04.5.5,1.14.75,1.91.75.72,0,1.33-.22,1.83-.67.5-.44.8-1.08.88-1.91h5.38c-.14,1.43-.57,2.7-1.29,3.81-.72,1.1-1.66,1.95-2.84,2.54-1.18.59-2.51.88-4,.88-1.83,0-3.43-.47-4.79-1.4Z" />
                <path d="m213.15,67.29c-1.22-.59-2.18-1.42-2.88-2.51-.7-1.09-1.05-2.37-1.05-3.83h5.2c.06.86.35,1.56.88,2.11.53.54,1.23.82,2.08.82.4,0,.8-.09,1.18-.28s.69-.43.9-.73.32-.65.32-1.05c0-.57-.18-1.06-.54-1.46-.36-.4-1-.73-1.91-.99l-2.54-.9c-1.66-.52-2.95-1.31-3.85-2.39-.9-1.08-1.35-2.4-1.35-3.98,0-1.32.33-2.48.99-3.48.66-1,1.55-1.77,2.69-2.3s2.39-.8,3.76-.8,2.72.27,3.87.82c1.15.54,2.05,1.33,2.71,2.34.66,1.02.99,2.19.99,3.5h-5.2c0-.75-.22-1.33-.67-1.76s-1.03-.65-1.74-.65c-.37,0-.73.09-1.08.26s-.61.41-.79.71c-.19.3-.28.65-.28,1.05,0,.52.17.95.5,1.29.33.34.92.66,1.78.95l2.54.86c1.72.57,3.04,1.43,3.96,2.56.92,1.13,1.38,2.5,1.38,4.11,0,1.35-.3,2.52-.9,3.5-.6.99-1.48,1.76-2.64,2.3-1.16.54-2.52.82-4.06.82s-3.02-.29-4.24-.88Z" />
                <path d="m232.5,66.78c-1.36-.93-2.39-2.25-3.1-3.96-.7-1.71-1.05-3.69-1.05-5.96s.36-4.33,1.08-6.02c.72-1.69,1.73-3,3.05-3.93,1.32-.93,2.87-1.4,4.64-1.4s3.23.43,4.54,1.29c1.3.86,2.31,2.14,3.01,3.85.7,1.71,1.05,3.71,1.05,6,0,.2,0,.4-.02.6-.01.2-.04.44-.06.73-.03.03-.04.05-.02.06s.02.05.02.11h-13.97v-3.83h11.78l-3.31,1.93c.06-1.2-.01-2.28-.21-3.22-.2-.95-.54-1.68-1.03-2.19-.49-.52-1.08-.77-1.76-.77s-1.25.24-1.76.71c-.52.47-.9,1.14-1.16,2-.26.86-.39,1.86-.39,3.01v2.02c0,1.09.14,2.06.43,2.92.29.86.68,1.54,1.18,2.04.5.5,1.14.75,1.91.75.72,0,1.33-.22,1.83-.67.5-.44.8-1.08.88-1.91h5.38c-.14,1.43-.57,2.7-1.29,3.81-.72,1.1-1.66,1.95-2.84,2.54-1.18.59-2.51.88-4,.88-1.83,0-3.43-.47-4.79-1.4Z" />
                <path d="m250.47,66.5c-1.1-1.12-1.66-2.58-1.66-4.39,0-2.12.72-3.82,2.15-5.1,1.43-1.28,3.54-2.11,6.32-2.52l3.01-.39v3.7l-1.89.3c-1.4.29-2.44.71-3.12,1.27-.67.56-1.01,1.28-1.01,2.17,0,.57.19,1.04.58,1.4.39.36.85.54,1.4.54.66,0,1.23-.19,1.72-.56.49-.37.88-.91,1.18-1.61.3-.7.45-1.48.45-2.34v-6.15c0-.86-.21-1.52-.64-1.98-.43-.46-1.02-.69-1.76-.69s-1.4.25-1.87.75c-.47.5-.72,1.21-.75,2.13h-5.46c.06-1.49.44-2.82,1.16-3.98s1.68-2.04,2.88-2.64c1.2-.6,2.55-.9,4.04-.9s2.87.31,4.06.92c1.19.62,2.11,1.51,2.75,2.69.65,1.18.97,2.55.97,4.13v9.25c0,.46.12.8.37,1.03s.58.34,1.01.34c.17-.06.36-.09.56-.09v4c-.54.14-1.2.21-1.98.21-1.09,0-1.99-.24-2.71-.73-.72-.49-1.19-1.26-1.42-2.32l-.56-2.24,3.05,2.24h-2.75c-.49.95-1.22,1.72-2.19,2.32-.97.6-2.17.9-3.57.9-1.78,0-3.22-.56-4.32-1.68Z" />
                <path d="m269.59,45.99h4.47l.47,3.31h.26c.4-1.12,1.06-1.99,1.98-2.62.92-.63,2.08-.95,3.48-.95.43,0,.8.03,1.12.09v5.2c-.4-.06-.82-.09-1.25-.09-1.46,0-2.67.54-3.63,1.61-.96,1.07-1.44,2.67-1.44,4.79v10.32h-5.46v-21.67Z" />
                <path d="m288.14,66.8c-1.39-.92-2.46-2.24-3.2-3.96-.75-1.72-1.12-3.71-1.12-5.98s.37-4.34,1.12-6.04c.75-1.71,1.81-3.02,3.2-3.93,1.39-.92,3.02-1.38,4.88-1.38,1.49,0,2.87.34,4.15,1.01,1.28.67,2.29,1.63,3.03,2.88s1.19,2.69,1.33,4.32h-5.59c-.09-1.09-.39-1.94-.92-2.56-.53-.62-1.2-.92-2-.92s-1.44.26-2,.77c-.56.52-.98,1.27-1.27,2.26-.29.99-.43,2.19-.43,3.59s.14,2.52.43,3.5c.29.99.71,1.74,1.27,2.26.56.52,1.23.77,2,.77s1.47-.3,2-.9c.53-.6.84-1.45.92-2.54h5.59c-.14,1.63-.59,3.07-1.33,4.3-.75,1.23-1.76,2.19-3.03,2.88-1.28.69-2.66,1.03-4.15,1.03-1.86,0-3.49-.46-4.88-1.38Z" />
                <path d="m315.83,51.15c-.5-.6-1.14-.9-1.91-.9-.54,0-1.05.14-1.5.41-.46.27-.83.67-1.12,1.2-.29.53-.43,1.24-.43,2.13v13.67h-5.46v-30.96h5.46v11.52h.26c.32-.69.85-1.31,1.59-1.87.75-.56,1.72-.84,2.92-.84s2.37.27,3.33.82c.96.54,1.71,1.38,2.26,2.49.54,1.12.82,2.44.82,3.96v14.88h-5.46v-13.98c0-1.09-.25-1.93-.75-2.54Z" />
                <path d="m166.48 76.06h5.76v30.1h-5.76v-30.1z" />
                <path d="m188,89.65c-.5-.6-1.14-.9-1.91-.9-.54,0-1.05.14-1.5.41-.46.27-.83.67-1.12,1.2-.29.53-.43,1.24-.43,2.13v13.67h-5.46v-21.67h4.47l.47,2.36h.26c.43-.77,1.06-1.44,1.89-2,.83-.56,1.88-.84,3.14-.84s2.37.27,3.33.82c.96.54,1.71,1.38,2.26,2.49.54,1.12.82,2.44.82,3.96v14.88h-5.46v-13.98c0-1.09-.25-1.93-.75-2.54Z" />
                <path d="m202.23,105.79c-1.22-.59-2.18-1.42-2.88-2.51-.7-1.09-1.05-2.37-1.05-3.83h5.2c.06.86.35,1.56.88,2.11.53.54,1.23.82,2.08.82.4,0,.8-.09,1.18-.28s.69-.43.9-.73.32-.65.32-1.05c0-.57-.18-1.06-.54-1.46-.36-.4-1-.73-1.91-.99l-2.54-.9c-1.66-.52-2.95-1.31-3.85-2.39-.9-1.08-1.35-2.4-1.35-3.98,0-1.32.33-2.48.99-3.48.66-1,1.55-1.77,2.69-2.3s2.39-.8,3.76-.8,2.72.27,3.87.82c1.15.54,2.05,1.33,2.71,2.34.66,1.02.99,2.19.99,3.5h-5.2c0-.75-.22-1.33-.67-1.76s-1.03-.65-1.74-.65c-.37,0-.73.09-1.08.26s-.61.41-.79.71c-.19.3-.28.65-.28,1.05,0,.52.17.95.5,1.29.33.34.92.66,1.78.95l2.54.86c1.72.57,3.04,1.43,3.96,2.56.92,1.13,1.38,2.5,1.38,4.11,0,1.35-.3,2.52-.9,3.5-.6.99-1.48,1.76-2.64,2.3-1.16.54-2.52.82-4.06.82s-3.02-.29-4.24-.88Z" />
                <path d="m223.75,105.79c-1.06-.59-1.89-1.4-2.47-2.43-.59-1.03-.88-2.18-.88-3.44v-10.92h-3.83v-4.51h.6c1.26,0,2.21-.34,2.84-1.03.63-.69,1-1.71,1.12-3.05l.09-2.02h4.69v6.11h4.56v4.51h-4.6v10.41c0,.75.25,1.35.75,1.83.5.47,1.15.71,1.96.71.49,0,1-.09,1.55-.26v4.56c-.89.29-1.81.43-2.75.43-1.35,0-2.55-.29-3.61-.88Z" />
                <path d="m234.09 80.83c-0.67-0.66-1.01-1.46-1.01-2.41 0-0.89 0.34-1.66 1.03-2.32s1.48-0.99 2.37-0.99 1.68 0.33 2.37 0.99 1.03 1.43 1.03 2.32c0 0.95-0.34 1.75-1.03 2.41s-1.49 0.99-2.41 0.99-1.67-0.33-2.34-0.99zm-0.41 3.66h5.46v21.67h-5.46v-21.67z" />
                <path d="m249.51,105.79c-1.06-.59-1.89-1.4-2.47-2.43-.59-1.03-.88-2.18-.88-3.44v-10.92h-3.83v-4.51h.6c1.26,0,2.21-.34,2.84-1.03.63-.69,1-1.71,1.12-3.05l.09-2.02h4.69v6.11h4.56v4.51h-4.6v10.41c0,.75.25,1.35.75,1.83.5.47,1.15.71,1.96.71.49,0,1-.09,1.55-.26v4.56c-.89.29-1.81.43-2.75.43-1.35,0-2.55-.29-3.61-.88Z" />
                <path d="m262.54,105.84c-.95-.56-1.7-1.39-2.26-2.49-.56-1.1-.84-2.44-.84-4.02v-14.83h5.46v14.02c0,1.09.24,1.93.73,2.52.49.59,1.1.88,1.85.88.54,0,1.03-.13,1.46-.39s.78-.66,1.05-1.2c.27-.54.41-1.25.41-2.11v-13.72h5.46v21.67h-4.47l-.47-2.32h-.26c-.4.75-1.01,1.41-1.83,1.98-.82.57-1.84.86-3.08.86s-2.28-.28-3.22-.84Z" />
                <path d="m286.27,105.79c-1.06-.59-1.89-1.4-2.47-2.43-.59-1.03-.88-2.18-.88-3.44v-10.92h-3.83v-4.51h.6c1.26,0,2.21-.34,2.84-1.03.63-.69,1-1.71,1.12-3.05l.09-2.02h4.69v6.11h4.56v4.51h-4.6v10.41c0,.75.25,1.35.75,1.83.5.47,1.15.71,1.96.71.49,0,1-.09,1.55-.26v4.56c-.89.29-1.81.43-2.75.43-1.35,0-2.55-.29-3.61-.88Z" />
                <path d="m299.67,105.28c-1.36-.93-2.39-2.25-3.1-3.96-.7-1.71-1.05-3.69-1.05-5.96s.36-4.33,1.08-6.02c.72-1.69,1.73-3,3.05-3.93,1.32-.93,2.87-1.4,4.64-1.4s3.23.43,4.54,1.29c1.3.86,2.31,2.14,3.01,3.85.7,1.71,1.05,3.71,1.05,6,0,.2,0,.4-.02.6-.01.2-.04.44-.06.73-.03.03-.04.05-.02.06s.02.05.02.11h-13.97v-3.83h11.78l-3.31,1.93c.06-1.2-.01-2.28-.21-3.22-.2-.95-.54-1.68-1.03-2.19-.49-.52-1.08-.77-1.76-.77s-1.25.24-1.76.71c-.52.47-.9,1.14-1.16,2-.26.86-.39,1.86-.39,3.01v2.02c0,1.09.14,2.06.43,2.92.29.86.68,1.54,1.18,2.04.5.5,1.14.75,1.91.75.72,0,1.33-.22,1.83-.67.5-.44.8-1.08.88-1.91h5.38c-.14,1.43-.57,2.7-1.29,3.81-.72,1.1-1.66,1.95-2.84,2.54-1.18.59-2.51.88-4,.88-1.83,0-3.43-.47-4.79-1.4Z" />
                <path d="m320.14,105.79c-1.22-.59-2.18-1.42-2.88-2.51-.7-1.09-1.05-2.37-1.05-3.83h5.2c.06.86.35,1.56.88,2.11.53.54,1.23.82,2.08.82.4,0,.8-.09,1.18-.28s.69-.43.9-.73.32-.65.32-1.05c0-.57-.18-1.06-.54-1.46-.36-.4-1-.73-1.91-.99l-2.54-.9c-1.66-.52-2.95-1.31-3.85-2.39-.9-1.08-1.35-2.4-1.35-3.98,0-1.32.33-2.48.99-3.48.66-1,1.55-1.77,2.69-2.3s2.39-.8,3.76-.8,2.72.27,3.87.82c1.15.54,2.05,1.33,2.71,2.34.66,1.02.99,2.19.99,3.5h-5.2c0-.75-.22-1.33-.67-1.76s-1.03-.65-1.74-.65c-.37,0-.73.09-1.08.26s-.61.41-.79.71c-.19.3-.28.65-.28,1.05,0,.52.17.95.5,1.29.33.34.92.66,1.78.95l2.54.86c1.72.57,3.04,1.43,3.96,2.56.92,1.13,1.38,2.5,1.38,4.11,0,1.35-.3,2.52-.9,3.5-.6.99-1.48,1.76-2.64,2.3-1.16.54-2.52.82-4.06.82s-3.02-.29-4.24-.88Z" />
              </g>
            </svg>

            <a
              className="font-light text-white block text-center"
              href="mailto:dsri@ul.org"
            >
              dsri@ul.org
            </a>
          </div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row justify-between gap-2 py-4 mx-auto mt-4 border-t font-light text-white max-w-7xl">
        <div className="flex mx-auto md:mx-0 md:mr-auto">
          <span>© {currentYear} UL Research Institutes</span>
        </div>
        {/* <div className="flex mx-auto md:mx-0 md:ml-auto justify-between text-xl gap-3">
          <FontAwesomeIcon icon={faTwitter} />
          <FontAwesomeIcon icon={faFacebook} />
          <FontAwesomeIcon icon={faLinkedin} />
        </div> */}
      </div>
    </div>
  </footer>
)

export default Footer
