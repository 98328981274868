import {
  faArrowUpRightFromSquare,
  faBars,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"
import React, { ReactElement, useState } from "react"

import DsriTypeTreatment from "../images/svg/dsri_type_treatment.svg"
import HeaderMenuItem from "./HeaderMenuItem"

const Header = (): ReactElement => {
  const [menuOpened, setMenuOpened] = useState(false)

  return (
    <header className="bg-white min-h-75 sticky top-0 z-10">
      <div className="shadow-md">
        <nav>
          <div className="flex mx-auto max-w-7xl items-center justify-between py-4 px-6">
            <div className="w-[400px]">
              <Link to="/">
                <DsriTypeTreatment />
              </Link>
            </div>
            {/* Menu available on medium and larger screens. */}
            <div className="block flex hidden xl:block">
              <HeaderMenuItem text="About" url="/about" />
              <HeaderMenuItem text="Blog" url="/blog" />
              <HeaderMenuItem text="Careers" url="/careers" />
              <HeaderMenuItem text="Outcomes" url="/outcomes" />
              <HeaderMenuItem
                text="DSRI News"
                external={true}
                url="https://ul.org/research/digital-safety#news"
              />
              <HeaderMenuItem text="Safety Alerts" url="/safety-alerts" />
            </div>
            {/* Mobile/small screen button. */}
            <div className="block xl:hidden">
              <button
                className={`flex items-center ml-5 px-3 py-2 rounded text-black
                                               hover:bg-[#F0F8FC] hover:text-ul-bright-blue
                                               ${
                                                 menuOpened
                                                   ? "bg-[#F0F8FC] text-ul-bright-blue"
                                                   : ""
                                               }`}
                onClick={() => setMenuOpened(!menuOpened)}
              >
                <FontAwesomeIcon
                  icon={faBars}
                  style={{ width: "16px", height: "16px" }}
                />
              </button>
            </div>
          </div>
          {/* Mobile/small screen menu only appears after button press. */}
          <div
            className={`px-6 pb-2 text-right md:hidden ${
              menuOpened ? "block" : "hidden"
            }`}
          >
            <Link to="/about" className="py-1 block">
              About
            </Link>
            <Link to="/blog" className="py-1 block">
              Blog
            </Link>
            <Link to="/careers" className="py-1 block">
              Careers
            </Link>
            <Link to="/outcomes" className="py-1 block">
              Outcomes
            </Link>
            <span>
              <a
                href="https://ul.org/research/digital-safety#news"
                target="_blank"
                className="py-1 block"
              >
                <FontAwesomeIcon
                  icon={faArrowUpRightFromSquare}
                  style={{ width: `16px`, height: `16px` }}
                />{" "}
                DSRI News
              </a>
            </span>
            <Link to="/safety-alerts" className="py-1 block">
              Safety Alerts
            </Link>
          </div>
        </nav>
      </div>
    </header>
  )
}

export default Header
