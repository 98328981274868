import React, { ReactElement, ReactNode } from "react"

import Footer from "./Footer"
import Header from "./Header"

import "../styles/global.css"

interface LayoutProps {
  children: ReactNode
}

const Layout = ({ children }: LayoutProps): ReactElement => (
  <div id="page-container" className="flex flex-col min-h-screen">
    <Header />
    <div id="content-container" className="grow w-full mx-auto">
      <main>{children}</main>
    </div>
    <Footer />
  </div>
)

export default Layout
