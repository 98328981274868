import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"
import React, { ReactElement } from "react"

type SubItem = {
  text: string
  url: string
  external?: boolean
}

type HeaderMenuItemProps = {
  id?: string
  text: string
  url: string
  external?: boolean
  subitems?: Array<SubItem>
}

const HeaderMenuItem = ({
  id,
  text,
  url,
  external = false,
  subitems = [],
}: HeaderMenuItemProps): ReactElement => {
  const button = (
    <button
      id={id}
      className="bg-transparent text-black font-medium inline-block py-2 px-4
               hover:bg-[#F0F8FC] hover:text-ul-bright-blue transition duration-300"
    >
      <span>
        {external && <FontAwesomeIcon icon={faArrowUpRightFromSquare} />} {text}
      </span>
    </button>
  )

  if (external) {
    return (
      // No active style/className needed -- should leave the site.
      // target="_blank": browser opens link in new tab by default.
      <a href={url} target="_blank">
        {button}
      </a>
    )
  }

  return (
    <div className="relative group inline-block">
      <Link
        to={url}
        activeClassName="[&_button]:text-ul-bright-blue"
        partiallyActive={true}
      >
        <button
          id={id}
          className="bg-transparent text-black font-medium inline-block py-2 px-4
               hover:bg-[#F0F8FC] hover:text-ul-bright-blue transition duration-300"
        >
          <span>
            {external && <FontAwesomeIcon icon={faArrowUpRightFromSquare} />}{" "}
            {text}
          </span>
        </button>
      </Link>
      {subitems && subitems.length > 0 && (
        <ul className="absolute hidden group-hover:block transition duration-300">
          {subitems.map((item, index) => (
            <li key={index}>
              <a
                className="bg-[#F0F8FC] py-2 px-4 block whitespace-no-wrap hover:bg-[#F0F8FC] hover:text-ul-bright-blue transition duration-300"
                href={item.url}
              >
                {item.text}
              </a>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default HeaderMenuItem
