import { PrismicRichText } from "@prismicio/react"
import { graphql, PageProps } from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import React from "react"

import { BibliographySectionDocument } from "../../../types.generated"
import BibliographySection from "../../components/BibliographySection"
import Page from "../../components/Page"
import ProseBlock from "../../components/ProseBlock"
import { SEO } from "../../components/SEO"
import linkResolver from "../../utils/LinkResolver"

export const Head = (props: PageProps<Queries.BibliographyPageQuery>) => {
  const title = props?.data?.prismicBibliography?.data?.title ?? "Outcomes"
  const description =
    props?.data.prismicBibliography?.data.main_text?.text ??
    "Outcomes of DSRI expert researchers."
  return (
    <SEO
      title={title}
      description={description}
      pathname={props.location.pathname}
    />
  )
}

const OutcomesPage = ({ data }: PageProps<Queries.BibliographyPageQuery>) => {
  const title = data?.prismicBibliography?.data?.title ?? "Outcomes"
  const sections = data?.prismicBibliography?.data?.sections ?? []
  return (
    <Page title={title}>
      <ProseBlock>
        <PrismicRichText
          field={data.prismicBibliography?.data.main_text.richText}
        />
      </ProseBlock>
      <div className="mt-4">
        {sections.map((section) => {
          const sectionData = (
            section.section?.document as BibliographySectionDocument
          ).data
          return <BibliographySection document={sectionData} />
        })}
      </div>
    </Page>
  )
}

export default withPrismicPreview(OutcomesPage, {
  repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
  linkResolver: linkResolver,
})

export const BibliographyPageQuery = graphql`
  query BibliographyPage {
    prismicBibliography {
      _previewable
      data {
        main_text {
          richText
        }
        sections {
          section {
            uid
            document {
              ... on PrismicBibliographySection {
                _previewable
                data {
                  title
                  intro_text
                  items {
                    item {
                      document {
                        ... on PrismicBibliographyItem {
                          _previewable
                          data {
                            display_text {
                              richText
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
