import React, { ReactNode } from "react"

import { useSiteMetadata } from "../hooks/use-site-metadata"

type SEOProps = {
  title?: string
  description?: string
  pathname?: string
  metaImage?: string
  children?: ReactNode
}

export const SEO = ({
  title,
  description,
  pathname,
  metaImage,
  children,
}: SEOProps) => {
  const {
    title: defaultTitle,
    description: defaultDescription,
    siteUrl,
  } = useSiteMetadata()

  const seo = {
    // If a title is provided
    title: (title && `${title} | ${defaultTitle}`) || defaultTitle,
    description: description || defaultDescription,
    url: `${siteUrl}${pathname || ``}`,
    metaImage:
      metaImage ||
      "https://dsri.org/static/stock_banner_2-fef2139d090ad05053df038532974e88.jpg",
  }

  return (
    <>
      <title>{seo.title}</title>
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.metaImage} />
      <link rel="canonical" href={seo.url} />
      <meta property="og:url" content={seo.url} />
      {children}
    </>
  )
}
