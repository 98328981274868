import React, { ReactElement, ReactNode } from "react"

import "../styles/global.css"

import Layout from "./Layout"

interface PageProps {
  title: string
  children: ReactNode
}

const Page = ({ title, children }: PageProps): ReactElement => (
  <Layout>
    <div className="container mx-auto max-w-5xl py-10 px-6">
      <h1 className="mb-8 text-5xl">{title}</h1>
      {children}
    </div>
  </Layout>
)

export default Page
