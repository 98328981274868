import React, { ReactElement, ReactNode } from "react"

import "../styles/global.css"

import Layout from "./Layout"

interface ProseBlockProps {
  children: ReactNode
}

const ProseBlock = ({ children }: ProseBlockProps): ReactElement => (
  <div className="container prose prose-p:mb-5 leading-8 mx-auto max-w-5xl font-light text-black">
    {children}
  </div>
)

export default ProseBlock
