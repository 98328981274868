import { PrismicRichText } from "@prismicio/react"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import React from "react"

import { BibliographyItemDocument } from "../../types.generated"
import linkResolver from "../utils/LinkResolver"
import ProseBlock from "./ProseBlock"

const BibliographyItem = ({ document }: BibliographyItemDocument) => {
  const displayText = document.item.document.data.display_text.richText
  return (
    <div className="py-2">
      <ProseBlock>
        <PrismicRichText field={displayText} />
      </ProseBlock>
    </div>
  )
}

export default withPrismicPreview(BibliographyItem, {
  repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
  linkResolver: linkResolver,
})
