/**
 * This file contains the Link Resolver function, which defines the URL paths for Prismic documents.
 * It helps Gatsby determine the correct paths for linking and routing content fetched from Prismic CMS.
 */

interface PrismicDocument {
  type: string
  uid?: string
}

const linkResolver = (doc: PrismicDocument): string => {
  if (
    doc.type === "bibliography" ||
    doc.type === "bibliography_section" ||
    doc.type === "bibliography_item"
  ) {
    return `/outcomes`
  }
  if (doc.type === "blog_post" && doc.uid) {
    return `/blog/${doc.uid}`
  }
  return "/"
}

export default linkResolver
