import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import React from "react"

import {
  BibliographyItemDocument,
  BibliographySectionDocument,
} from "../../types.generated"
import linkResolver from "../utils/LinkResolver"
import BibliographyItem from "./BibliographyItem"
import Collapsible from "./Collapsible"

const BibliographySection = ({ document }: BibliographySectionDocument) => {
  const title = document.title
  const introText = document.intro_text
  const items = document.items
  return (
    <Collapsible
      title={title}
      subtitle={introText}
      open={true}
      className="mt-4"
    >
      <div className="flex flex-col divide-y md:divide-solid">
        {items.map((item: BibliographyItemDocument) => (
          <BibliographyItem document={item} />
        ))}
      </div>
    </Collapsible>
  )
}

export default withPrismicPreview(BibliographySection, {
  repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
  linkResolver: linkResolver,
})
