import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { ReactNode, useState } from "react"

interface CollapsibleProps {
  title: string
  subtitle?: string
  children: ReactNode
  open?: boolean
  className?: string
}

const Collapsible: React.FC<CollapsibleProps> = ({
  title,
  subtitle = "",
  children,
  open = false,
  className = "",
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(open)

  return (
    <div className={`${className}`}>
      <button
        className="flex justify-between items-center w-full p-4 bg-gray-100 hover:bg-gray-200"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div>
          <div className="text-left text-lg font-semibold">{title}</div>
          <div className="text-left text-sm">{subtitle}</div>
        </div>
        <FontAwesomeIcon
          icon={faChevronDown}
          className={`transition-transform transform ${isOpen ? "rotate-180" : ""}`}
        />
      </button>
      {isOpen && <div className="p-4">{children}</div>}
    </div>
  )
}

export default Collapsible
