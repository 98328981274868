import "./src/styles/global.css"
import "./src/styles/slick-theme.css"
import "@fortawesome/fontawesome-svg-core/styles.css"

import { PrismicLink, PrismicProvider } from "@prismicio/react"
import { Link as GatsbyLink } from "gatsby"
import { PrismicPreviewProvider } from "gatsby-plugin-prismic-previews"
import * as React from "react"

import OutcomesPage from "./src/pages/outcomes"
import linkResolver from "./src/utils/LinkResolver"

const prismicRepoName = process.env.GATSBY_PRISMIC_REPO_NAME
if (!prismicRepoName) {
  throw new Error("GATSBY_PRISMIC_REPO_NAME environment variable is not set.")
}

const richTextComponents = {
  hyperlink: ({ children, node }) => (
    <PrismicLink field={node.data}>{children}</PrismicLink>
  ),
}

export const wrapRootElement = ({ element }) => (
  <PrismicProvider
    internalLinkComponent={({ href, ...props }) => (
      <GatsbyLink to={href} {...props} />
    )}
    linkResolver={linkResolver}
    richTextComponents={richTextComponents}
  >
    <PrismicPreviewProvider
      repositoryConfigs={[
        {
          repositoryName: prismicRepoName,
          linkResolver,
          componentResolver: {
            bibliography: OutcomesPage,
            bibliography_section: OutcomesPage,
            bibliography_item: OutcomesPage,
          },
        },
      ]}
    >
      {element}
    </PrismicPreviewProvider>
  </PrismicProvider>
)
